import {
  useGetIntroStepStatusesQueryState,
  usePostIntroStepStatusMutation,
} from 'Api'
import { useRef } from 'react'

const useCompleteStep = (id: string) => {
  const hasCompleted = useRef<boolean>(false)
  const { data } = useGetIntroStepStatusesQueryState()
  const interactions = data?.interactions
  const hasCompletedInteraction = interactions?.some((i) => i.id === id)
  const [trigger] = usePostIntroStepStatusMutation()

  const completeStep = () => {
    if (!hasCompletedInteraction && hasCompleted.current === false) {
      hasCompleted.current = true
      void trigger({ interactions: [{ id }] }).catch(() => {
        hasCompleted.current = false
      })
    }
  }

  return completeStep
}

export default useCompleteStep
