import { useAuthentication } from '@trr/app-shell-data'
import { useGetAnsokanQuery } from 'Api'
import React, { PropsWithChildren } from 'react'
import { isApprovedAfterWidgetStartDate } from 'utils'

const FetchAnsokan = ({ children }: PropsWithChildren) => {
  const { sub } = useAuthentication()
  const { data: ansokan, isLoading } = useGetAnsokanQuery(sub, {
    skip: sub === null,
  })

  const ansokanIsWithin30DaysOfCreation = Boolean(
    ansokan?.komplettdatum &&
      isApprovedAfterWidgetStartDate(ansokan.komplettdatum)
  )
  if (isLoading || ansokanIsWithin30DaysOfCreation === false) {
    return null
  }

  return <>{children}</>
}

export default FetchAnsokan
