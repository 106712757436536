import { User } from '@trr/app-shell-data'

interface Variables {
  namn: string
}

const variables: Variables = {
  namn: '{{namn}}',
}

export const replaceVariablesInText = (
  text: string,
  user: User | null
): string => {
  const replaceStrings: Variables = {
    namn: user?.preferredFirstName ?? user?.firstName ?? '',
  }

  const regex = (key: string): RegExp => new RegExp(`{{${key}}}`, 'g')
  Object.keys(variables).forEach((key) => {
    text = text.replace(regex(key), replaceStrings[key as keyof Variables])
  })
  return text
}
