import { createApi } from '@reduxjs/toolkit/query/react'
import { extendedBaseQuery } from 'utils'

export interface UserInteractionsResponse {
  userId: string
  interactions: {
    id: string
    createdTime: string
    deletedTime: string | null
  }[]
}

interface AktuellAnsokanResponse {
  aktuellAnsokan?: {
    komplettdatum: string | null
  }
}

export const ansokanApi = createApi({
  reducerPath: 'ansokanApi',
  baseQuery: extendedBaseQuery('KLIENT_API_URL'),
  endpoints: (builder) => ({
    getAnsokan: builder.query<
      AktuellAnsokanResponse['aktuellAnsokan'],
      string | null
    >({
      query: (sub) => `/klient/${sub}/ansokan`,
      transformResponse: (response: AktuellAnsokanResponse) =>
        response.aktuellAnsokan,
    }),
  }),
})

export const { useGetAnsokanQuery } = ansokanApi

export default ansokanApi
