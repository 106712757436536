import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { introStepsApi, ansokanApi } from '../Api'

const rootReducer = combineReducers({
  [introStepsApi.reducerPath]: introStepsApi.reducer,
  [ansokanApi.reducerPath]: ansokanApi.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        introStepsApi.middleware,
        ansokanApi.middleware,
      ]),
    preloadedState,
  })

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export default store
