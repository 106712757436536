import { HubConnectionState } from '@microsoft/signalr'
import { useSignalR } from '@trr/app-shell-data'
import { introStepsApi } from 'Api'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const useUserInteractionsUpdatedListener = () => {
  const connection = useSignalR()?.klient
  const dispatch = useDispatch()
  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Connected) {
      connection.on('userInteractionUpdated', () => {
        dispatch(introStepsApi.util.invalidateTags(['introStepStatuses']))
      })
      return () => {
        connection.off('userInteractionUpdated')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useUserInteractionsUpdatedListener
