import { Icon as MuiFontIcon } from '@mui/material'
import React from 'react'
import allowedIcons from './allowedIcons.json'
import { MuiColors } from 'types'

interface AllowedIcons {
  [key: string]: 1
}

const pascalToSnakeCase = (text: string): string => {
  return text
    ?.replace(/(.)([A-Z][a-z]+)/, '$1_$2')
    ?.replace(/([a-z0-9])([A-Z])/, '$1_$2')
    ?.toLowerCase()
}

export interface IconProps {
  iconName: string
  size?: 'small' | 'medium' | 'large'
  color?: MuiColors
}

const FontIcon = ({ iconName, size, color }: IconProps): JSX.Element => {
  const snakeCaseIconName = pascalToSnakeCase(iconName)
  const foundIcon = (allowedIcons as AllowedIcons)[snakeCaseIconName]

  return (
    <MuiFontIcon fontSize={size} sx={{ color: `${color}.main` }}>
      {foundIcon ? snakeCaseIconName : 'favorite'}
    </MuiFontIcon>
  )
}

export default FontIcon
