import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { content } from './types'
import { IntroList } from 'features'
import { useUserInteractionsUpdatedListener } from 'hooks'
import { buildImageUrl } from 'utils'
import { useGetIntroStepStatusesQueryState } from './Api/introStepsApi'
import { useTrackLoaded } from '@trr/gtm-tracking'

const App = () => {
  const user = useAuthentication()
  const { data: completedIntroSteps } = useGetIntroStepStatusesQueryState()
  const { imageUrl, heading, ingress, introSteps } = useEpiContent<content>()

  const [
    hasAllStepsCompletedOnFirstRender,
    setHasAllStepsCompletedOnFirstRender,
  ] = useState(false)

  const [isClosed, setIsClosed] = useState(
    Boolean(sessionStorage.getItem(`introWidgetClosed-${user.sub}`))
  )
  const handleCloseButtonClick = () => {
    setIsClosed(true)
    sessionStorage.setItem(`introWidgetClosed-${user.sub}`, 'true')
  }
  const triggerMfLoaded = useTrackLoaded()
  useUserInteractionsUpdatedListener()

  useEffect(
    () => {
      const hasAllInteractionsCompleted = introSteps.every((step) =>
        completedIntroSteps?.interactions.find((i) => i.id === step.identifier)
      )
      if (hasAllInteractionsCompleted) {
        setHasAllStepsCompletedOnFirstRender(true)
      } else {
        triggerMfLoaded('introwidget')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (hasAllStepsCompletedOnFirstRender) {
    return null
  }
  if (isClosed) {
    return null
  }
  return (
    <Card data-testid="introWidget">
      <CardHeader
        sx={{
          backgroundColor: 'surface.purple',
          pb: 3,
          backgroundImage: { md: `url(${buildImageUrl(imageUrl)})` },
          backgroundRepeat: { md: 'no-repeat' },
          backgroundPosition: { md: `calc(100% - 56px) 0` },
        }}
        action={
          <IconButton
            sx={{ mt: -2 }}
            data-gtm-label="Dölj kom igång widget"
            onClick={handleCloseButtonClick}
            aria-label="Dölj kom igång widget"
            data-testid="closeButton"
          >
            <CloseIcon />
          </IconButton>
        }
        title={
          <Typography component="h2" variant="h3">
            {heading}
          </Typography>
        }
        subheader={<Typography variant="body2">{ingress}</Typography>}
      />
      <CardContent
        sx={{
          p: 2,
          '&:last-child': {
            pb: 2,
          },
        }}
      >
        <IntroList />
      </CardContent>
    </Card>
  )
}

export default App
