import { getConfig } from '../getConfig'

const buildImageUrl = (imageUrl: string): string => {
  if (imageUrl?.startsWith('http')) {
    return imageUrl
  }
  return `${getConfig().MEDIA_URL}${
    imageUrl?.startsWith('/') ? imageUrl : `/${imageUrl}`
  }`
}

export default buildImageUrl
